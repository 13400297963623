<template>
  <div>
    <Header :info="info" />
    <div class="container">
      <BreadCrumb :info="[{ id:0, title: $t('İletişim Bilgilerimiz') }]" />
      <div class="mb catalog">
        <div class="mapCard">
          <gmaps-map :options="options" style="height:300px; border:1px solid #ccc">
            <gmaps-marker :position="{ lat: options.center.lat, lng: options.center.lng }" />
          </gmaps-map>
          <div>
            <h3>{{ company.PAGE_UNVAN }} {{ $t('Merkez') }}</h3>
            <ul class="contact">
              <li><i class="fa fa-map-marker"></i> {{ company.PAGE_ADRES }}</li>
              <li><i class="fa fa-phone-alt"></i> <a :href="'tel:' + company.PAGE_PHONE">{{ company.PAGE_PHONE }}</a></li>
              <li><i class="fa fa-envelope"></i> {{ company.PAGE_EPOSTA }}</li>
              <li style="grid-template-columns:1fr"><button @click="getDirection('directions')" class="btn1"><i class="fa fa-directions"></i> {{ $t('Yol Tarifi Al') }}</button></li>
              <form action="https://maps.google.com/maps" style="display:none" method="get" target="_blank" id="directions">
                <input type="hidden" name="daddr" :value="options.center.lat + ',' + options.center.lng" />
              </form>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer :info="info" />
  </div>
</template>

<style scoped lang="scss">
.mapCard {
  display:grid; grid-template-columns:repeat(1,1fr); gap:20px; padding-bottom:20px; margin-bottom:20px; border-bottom:1px solid #ccc
}
.btn1 {
  width:150px;height:40px;
  background:var(--color2);
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.1s ease-in;
  i {
    color:#fff !important;
    font-size:15px !important;
  }
}
.btn1:hover {
  filter: brightness(1.1);
}
.catalog {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 30px;
  min-height: calc(100vh - 400px);
}
.contact {
  list-style: none;
  li {
    display: grid;
    grid-template-columns: 28px auto;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    i {
      font-size:12px;
      text-align: center;
      color: #5c5c5c;
    }
  }
}
@media screen and (max-width:768px) {
  .mapCard {
    display:grid; grid-template-columns:repeat(1,1fr);
  }
}
</style>

<script>
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import { gmapsMap, gmapsMarker } from 'x5-gmaps'
export default {
  components: { BreadCrumb, gmapsMap, gmapsMarker },
  props: ['info'],
  data () {
    return {
      company: this.info.companyInfo,
      options: {
        center: {
          lat: parseFloat(this.info.companyInfo.PAGE_KOOR.split(',')[0]),
          lng: parseFloat(this.info.companyInfo.PAGE_KOOR.split(',')[1])
        },
        zoom: 15,
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: true,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false
      },
      options2: {
        center: {
          lat: 40.221654,
          lng: 28.986979
        },
        zoom: 15,
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: true,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false
      }
    }
  },
  methods: {
    getDirection: function (formId) {
      document.querySelector('#' + formId).submit()
    }
  },
  metaInfo () {
    const title = this.$i18n.t('İletişim')
    return {
      title: title,
      meta: [{ vmid: 'description', name: 'description', content: '' }]
    }
  }
}
</script>
