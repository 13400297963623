<template>
  <div>
    <header class="container">
      <router-link :alt="$tc('Rent a Tech')" :title="$tc('Anasayfa')" :to="{ name: 'Index' }">
        <div class="logo"></div>
      </router-link>
      <nav v-if="$store.state.width > 768">
        <router-link :to="{ name: 'HowItWorks' }">{{ $t('Nasıl Çalışır') }}</router-link>
        <router-link :to="{ name: 'BestSellers' }">{{ $t('En Popüler Ürünler') }}</router-link>
        <router-link :to="{ name: 'BestSellers' }">{{ $t('İşletmeniz İçin') }}</router-link>
        <a></a>
      </nav>
      <div v-else style="display:grid; align-items:center; justify-content:right;width:100%">
        <router-link :to="{ name: 'HowItWorks' }">{{ $t('Nasıl Çalışır') }}</router-link>
      </div>
      <div v-if="$store.state.width > 768" class="right">
        <div class="right" v-if="!$store.state.user.userId">
          <router-link :to="{ name: 'Login' }"><i class="fa fa-key"></i> {{ $t('Giriş Yapın') }}</router-link>
          <router-link class="primary" :to="{ name: 'Register' }"><i class="fa fa-user-plus"></i> {{ $t('Üye Olun') }}</router-link>
        </div>
        <div class="right" v-else>
          <a></a>
          <router-link class="primary" :to="{ name: 'MyAccountOrders' }"><i class="fa fa-user-lock"></i> {{ $t('Hesabım') }}</router-link>
        </div>
      </div>
    </header>
    <div class="container secondRow">
      <div>
        <a v-if="$store.state.width < 768" @click="toggleMenu(true)" class="cartButton catsButton bs">
          <i class="fa fa-list-ul" style="text-align:center;margin-left:8px;"></i>
        </a>
        <a v-else @mouseover="toggleMenu(true)" class="cartButton catsButton bs">
          <i class="fa fa-list-ul" style="text-align:center;margin-left:8px;"></i><span>{{ $t('Kategoriler') }}</span>
        </a>
        <transition name="fadasde2">
          <div v-if="$store.state.catMenu">
            <ul class="menu container" @mouseleave="toggleMenu(false)">
              <div class="cont">
                <li v-for="item in menu" :key="item.id">
                  <router-link @click="toggleMenu(false)" :to="{ name: 'Products', params: { id: item.id, top: 0 } }">
                    {{ item.title }}
                  </router-link>
                </li>
                <div style="border-top:1px solid #ccc;"  v-if="$store.state.width < 768">
                  <li><a>Nasıl Çalışır</a></li>
                  <li><a>Çok Satanlar</a></li>
                  <li><a>İşletmeniz İçin</a></li>
                </div>
                <div style="border-top:1px solid #ccc;"  v-if="$store.state.width < 768">
                  <li><a>Giriş Yapın</a></li>
                  <li><a>Üye Olun</a></li>
                </div>
              </div>
            </ul>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="$store.state.catMenu" @click.self="toggleMenu(false)" class="overlay"></div>
        </transition>
      </div>
      <div class="searchContainer">
        <input name="search" autocomplete="off" placeholder="Arayın" @keydown.enter="makeSearch" type="text" v-model.trim="searchField">
      </div>
      <div>
        <router-link :to="{ name: 'Cart' }" class="cartButton bs">
          <div class="cartIcon"></div>
          <div class="cartCount" v-if="$store.state.cart > 0">{{ $store.state.cart }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      subMenu: false,
      menuOpen: false,
      menu: this.info.menu,
      promo: this.$store.state.info.topPromoDesktop,
      searchField: ''
    }
  },
  mounted () {
    if (this.$route.params.search) {
      this.searchField = this.$route.params.search
    }
  },
  methods: {
    toggleMenu: function (state) {
      this.$store.dispatch('setCatMenu', state)
    },
    makeSearch: function () {
      const s = this.searchField.trim()
      if (s.length > 1) {
        this.$router.push({ name: 'Search', params: { search: s } })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.overlay {
  background:rgba(0,0,0,0.3); width:100%; height:100%; position:absolute; z-index:2; top:0; left:0;
}
.menu {
  position:absolute;
  z-index: 3;
  top: 60px;
  .cont {
    box-shadow: 0px 0px 34px 0px rgba(0,0,0,0.2);
    padding:13px;
    border-radius:12px;
    background: #fff;
    margin-top: 72px;
  }
  li {
    list-style:none;
    a {
      display:block;
      text-align:left;
      padding:12px;
      transition: 0.1s ease-in all;
    }
  }
}
.secondRow {
  display: grid;
  grid-template-columns: 180px auto 40px;
  gap: 15px;
}
.bs {
  box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
  transition: all 0.2s ease-out;
}
.bs:hover, .bs:focus {
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.22);
}
.catsButton {
  width: 100% !important;
  font-size:14px;
  border-radius:20px;
  letter-spacing:1px;
  text-transform:unset;
  grid-template-columns: 34px auto;
  align-items:center;
  justify-content:left;
}
.cartIcon {
  width:40px;
  height:40px;
  display: block;
  mask: url(../assets/icons/cart.svg) no-repeat;
  mask-size: 20px;
  mask-position: center;
  background-color: #000;
}
.cartCount {
  font-family: var(--font2);
  position: absolute;
  margin-left: 26px;
  background: var(--color2);
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 10px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  display: grid;
  place-content: center;
}
.searchContainer {
  input {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
    background: white;
    border: none;
    height: 40px;
    padding:16px;
    width:100%;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.2s ease-out;
    padding-left: 40px;
    background: url(../assets/icons/mag.svg) no-repeat;
    background-size: 16px;
    background-position: 16px 12px;
    background-color: #fff;
  }
  input:focus, input:hover {
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.22);
  }
  input::placeholder {
    color: #c0c0c0;
  }
}
.cartButton {
  background: #fff;
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 20px;
  font-size: 16px;
  i {
    transition: all 0.2s ease-out;
    color: #666;
  }
}
.cartButton:hover {
  i {
    color: var(--color2);
  }
}
a {
  font-family: var(--font1);
  color: black;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.04px;
  width: 100%;
  text-align: center;
  transition: all 0.2s ease-out;
  white-space: nowrap;
}
a:hover, a.active {
  color: var(--color2);
}
header {
  padding-top: 20px;
  padding-bottom: 14px;
  display: grid;
  grid-template-columns: 180px calc(100% - 480px) 300px;
  align-content: center;
  justify-items: left;
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  .right {
    width: 100%;
    display: flex;
    gap: 15px;
    place-items: center;
    justify-content: flex-end;
    .primary {
      padding: 14px;
      background: var(--color2);
      color: white;
      border-radius: 32px;
      font-size: 15px;
      font-weight: 600;
    }
    .primary:hover {
      background-color: var(--color1);
      box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3);
    }
  }
}

.logo {
  width: 144px;
  height: 40px;
  display: grid;
  align-items:center;
  background: url('../assets/logo.svg') center no-repeat;
  background-size: contain;
}
.sticky {
  width: calc(100% - 40px);
  margin-left: 20px;
  position: sticky !important;
  top: 20px;
  z-index: 4;
}
@media screen and (max-width:768px) {
  .secondRow {
    display: grid;
    grid-template-columns: 40px auto 40px;
    gap: 15px;
  }
  header {
    grid-template-columns: 180px auto;
  }
  .menu {
    .cont {
      margin-top:0px;
    }
  }
  .overlay {
    backdrop-filter: blur(20px);
  }
}
</style>
