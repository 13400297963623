<template>
  <div>
    <div class="container" style="min-height:850px" v-if="!isLoading && !error">
      <BreadCrumb :info="breadCrumb" v-if="showBc" />
      <Bcjsonld :jsonld = "jsonld" />
      <div class="pContainer">
        <div>
          <img style="margin-bottom:30px;border-radius:12px" v-for="image in product.images" :key="image.id" :src="mediaCdn + '/1800_' + image" width="100%">
          <ProductSpecs :specs="product.specs" />
        </div>
        <div>
          <div style="position:sticky; top:20px">
            <NormalProduct2 :product="product" v-if="productType === 'normal'" />
          </div>
        </div>
      </div>
      <Collection v-if="product.relatedProducts" :item="!product.relatedProducts" />
    </div>
    <div v-else style="min-height:850px; display:grid;place-items:center">
      <img src="~@/assets/loading-pulse.svg">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import ProductImages from '@/small-components/ProductImages.vue'
import BreadCrumb from '@/small-components/BreadCrumb.vue'
import NormalProduct2 from '@/components/NormalProduct2.vue'
import ProductSpecs from '@/small-components/ProductSpecs.vue'
import Collection from '@/home-components/Collection.vue'
import Bcjsonld from '@/small-components/Bcjsonld'

export default {
  components: { BreadCrumb, NormalProduct2, ProductSpecs, Collection, Bcjsonld },
  metaInfo () {
    return {
      title: this.product.title,
      meta: [
        { vmid: 'description', name: 'description', content: this.product.title }
      ]
    }
  },
  data: function () {
    return {
      pid: this.$route.params.pid,
      mediaCdn: this.$store.state.mediaCdn,
      isLoading: false,
      isSaving: false,
      productType: '',
      product: '',
      variants: [],
      variantGroup: null,
      productPrice: 0,
      carpan: null,
      kdvOran: null,
      kdv: 0,
      termin: null,
      price: 0,
      totalPrice: 0,
      breadCrumb: [],
      jsonld: null,
      error: null,
      showBc: true
    }
  },
  beforeMount () {
    this.itemDetails()
  },
  watch: {
    $route (to, from) {
      const hash = to.hash
      const fromHash = from.hash
      if ((!hash) && (!fromHash)) {
        this.pid = to.params.pid
        this.itemDetails()
      }
    }
  },
  methods: {
    itemDetails: async function () {
      this.isLoading = true
      const payload = {
        pid: this.pid,
        user: this.$store.state.user
      }
      await axios.post('api/template.php', payload).then(response => {
        if (response.data.error === 'none') {
          this.productType = response.data.productType
          this.breadCrumb = response.data.bc
          this.product = response.data.product
          this.isLoading = false
          this.$fnc.pageView(this)
          const images = this.product.images.map(
            x => {
              return this.$store.state.mediaCdn + '1500_' + x
            }
          )
          const jsonld = {
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: this.product.title,
            image: images,
            description: this.product.description,
            sku: this.product.sku,
            gtin13: this.product.barcode,
            brand: {
              '@type': 'Brand',
              name: this.product.brandName
            },
            offers: {
              '@type': 'Offer',
              url: window.location.href,
              priceCurrency: 'TRY',
              price: this.product.price,
              priceValidUntil: this.product.validUntil,
              itemCondition: 'https://schema.org/NewCondition',
              availability: 'https://schema.org/InStock',
              seller: {
                '@type': 'Organization',
                name: this.$store.state.baseUrl
              }
            }
          }
          this.jsonld = jsonld
          this.error = null
        } else {
          this.error = response.data.error
          setTimeout(() => {
            this.$router.push({ name: '404', params: { catchAll: this.$i18n.t(this.$fnc.seo(response.data.error)) } })
          }, 400)
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.pContainer {
  display:grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width:768px) {
  .pContainer {
    grid-template-columns: 1fr;
    gap:12px;
  }
}
</style>
