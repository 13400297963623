<template>
  <div></div>
</template>

<script>
// import FixedNavbar from '@/components/FixedNavbar.vue'
export default {
  // components: { FixedNavbar },
  props: ['info']
}
</script>
