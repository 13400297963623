<template>
  <div class="container" v-if="item">
    <div class="textArea">
      <h1 v-if="item.title">{{ item.title }} </h1>
    </div>
    <div class="mainProducts">
      <div class="snapper">
        <ProductCard :p="prod" v-for="prod in item.subs" :key="prod.id" />
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '@/small-components/ProductCard.vue'
export default {
  props: ['item'],
  components: { ProductCard },
  data () {
    return {
      mediaCdn: this.$store.state.mediaCdn
    }
  }
}
</script>

<style scoped lang="scss">
.textArea {
  width: 100%;
  margin: auto;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 0px;
  h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 9px;
  }
}
.mainProducts {
  margin-bottom: 20px;
  width: 100%;
  .snapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-content: space-between;
  }
  .snapper a:last-child {
    display:none;
  }
}
@media screen and (max-width: 410px) {
  .mainProducts {
    .snapper{
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media screen and (min-width:410px) and (max-width: 768px) {
  .mainProducts {
    .snapper{
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media screen and (min-width:768px) and (max-width: 1023px) {
  .mainProducts {
    .snapper{
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and  (min-width:1520px) {
  .mainProducts {
    .snapper{
      grid-template-columns: repeat(5, 1fr);
    }
    .snapper a:last-child {
      display:block;
    }
  }
}
</style>
