import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSplide from '@splidejs/vue-splide'
import x5GMaps from 'x5-gmaps'
import VueMeta from 'vue-meta'

import '@splidejs/splide/dist/css/themes/splide-default.min.css'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import Header from '@/components/Header.vue'
import GlassModal from '@/components/GlassModal.vue'
import SimpleHeader from '@/components/SimpleHeader.vue'
import Home from '@/components/Home.vue'
import Footer from '@/components/Footer.vue'
import SimpleFooter from '@/components/SimpleFooter.vue'
import Products from '@/components/Products.vue'
import { fnc } from './fnc.js'
import * as rax from 'retry-axios'
import i18n from './i18n'
import VueJsonLD from 'vue-jsonld'
import VueGtag from 'vue-gtag'

Vue.component('Header', Header)
Vue.component('GlassModal', GlassModal)
Vue.component('Home', Home)
Vue.component('Footer', Footer)
Vue.component('Products', Products)
Vue.component('SimpleHeader', SimpleHeader)
Vue.component('SimpleFooter', SimpleFooter)
Vue.use(VueSplide)
Vue.use(VueToast, {
  position: 'top-right'
})
Vue.use(VueAxios, axios)
Vue.use(x5GMaps, {
  key: 'AIzaSyBqD23PQG_GhFUZTPpO0TKFSG0TSrCHaAM',
  libraries: ['visualization', 'places']
})
Vue.use(VueMeta)
Vue.use(VueJsonLD)
Vue.use(VueGtag, {
  pageTrackerExcludedRotues: ['Index', 'Products'],
  config: {
    id: '', // analytics code (UA-XXXXXX)
    params: {
      send_page_view: true
    }
  }
})
axios.defaults.baseURL = 'https://9mhz.com/'
function detectLanguage () {
  let locale = ''
  const savedLocale = localStorage.getItem('lang')
  if (savedLocale) {
    locale = savedLocale
  } else {
    const bLocale = window.location.pathname.replace('/', '').trim().substring(0, 2)
    const langs = ['tr']
    if (langs.includes(bLocale)) {
      locale = bLocale
    } else {
      locale = 'tr'
    }
  }
  return locale
}
const lang = detectLanguage()
axios.defaults.headers.common.lang = lang
document.querySelector('html').setAttribute('lang', lang)
const retry = 6
axios.defaults.raxConfig = {
  instance: axios,
  retry: retry,
  noResponseRetries: retry,
  retryDelay: 750,
  httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'POST'],
  onRetryAttempt: err => {
    const cfg = rax.getConfig(err)
    if (cfg.currentRetryAttempt === retry) {
      Vue.$toast.error('İşlem tamamlanamadı, lütfen sayfayı yenileyin', {
        position: 'bottom-right',
        duration: 5000,
        onClick: function () {
          location.reload()
        }
      })
    }
    console.log(`Retry attempt #${cfg.currentRetryAttempt}`)
  }
}
rax.attach(axios)
// axios.defaults.timeout = 1200

Vue.config.productionTip = false
Vue.prototype.$fnc = fnc

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
