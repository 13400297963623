<template>
  <router-link :to="{ name: 'Product', params: {pid: p.p.urunlerId + '-' + $fnc.seo(p.p.urunlerAdi) } } " class="product" v-if="p">
    <div class="imageContainer">
      <div v-if="p.images.length === 0"></div>
      <div v-else-if="p.images.length > 1">
        <div v-if="hoverSlide" style="position:relative;">
          <img loading="lazy" :alt="p.p.urunlerAdi" :src="'https://www.kareiki.com/rentatech/media/900_' + p.images[index].source">
        </div>
        <div style="width:230px;display:flex;position:absolute;height:240px;margin-top:-240px;" v-if="hoverSlide">
          <div @mouseover="selectSlide(idx)" v-for="(i, idx) in p.images" :key="idx" style="width:100%"><i class="fa fa-square"></i></div>
        </div>
        <splide :options="options" v-if="!hoverSlide">
          <splide-slide v-for="image in p.images" :key="image.source">
            <img :alt="p.p.urunlerAdi" :src="'https://www.kareiki.com/rentatech/media/900_' + image.source">
          </splide-slide>
        </splide>
      </div>
      <div v-else>
        <img loading="lazy" style="user-drag: none;" :alt="p.p.urunlerAdi" :src="'https://www.kareiki.com/rentatech/media/900_' + p.images[0].source">
      </div>
    </div>
    <div class="text">
      <p class="title">{{ p.p.urunlerAdi }}</p>
      <p class="specs">{{ p.p.altBaslik }}</p>
      <p v-if="p.price" class="priceContainer">
        <span v-if="$store.state.priceWithVat">
          {{ parseFloat(p.price * (1 + (p.p.urunlerKdv / 100))).toFixed(2) }}
        </span>
        <span v-else>
          <span v-if="p.listPrice > p.price" style="display:flex; place-items:center">
            <span style="text-decoration:line-through;font-size:10px;margin-right:4px">{{ parseFloat(p.listPrice).toFixed(2) }} </span>
            <span style="color:red;">{{ parseFloat(p.price).toFixed(2) }}₺ </span>
          </span>
          <span v-else>{{ parseFloat(p.price).toFixed(2) }}₺</span>
        </span>
        den başlayan aylık fiyatlar
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['p', 'prices'],
  methods: {
    selectSlide: function (idx) {
      this.index = idx
    }
  },
  data () {
    return {
      hoverSlide: false,
      index: 0,
      options: {
        rewind: true,
        perpage: 1,
        autoWidth: false,
        autoHeight: false,
        arrows: false,
        pagination: true,
        lazyLoad: false,
        autoplay: false,
        type: 'loop',
        keyboard: false
      }
    }
  }
}
</script>
<style lang="scss">
.imageContainer {
  .splide__pagination{
    bottom: -15px;
  }
  .splide__pagination__page {
    background:#555;
    transform:scale(0.7);
    margin-right: 4px;
    margin-left: 4px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .splide__pagination__page.is-active {
    background:var(--color1);
    transform:scale(1);
  }
}
</style>
<style scoped lang="scss">
.imageContainer {
  // border: 1px solid red;
  padding-top: 30px;
  padding-bottom: 30px;
}
a {
  color: black;
}
a:hover {
  // color: var(--color1);
}
.product {
  cursor: pointer;
  width: 100%;
  text-align: center;
  background:#fff;
  padding-bottom:12px;
  border-radius: 9px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  transition: all 0.2s;
  .text {
    padding:8px;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    min-height: 100px;
  }
  .title {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
  }
  .specs {
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    margin-top: 8px;
  }
  .priceContainer {
    font-weight: normal;
    text-align: left;
    margin-top: 16px;
    font-size: 11px;
    color:#999;
    display: flex;
    place-items: center;
    gap:5px;
    span {
      color: #333;
      font-size: 15px;
      font-weight: bold;
    }
  }
  img {
    width: 100%;
  }
}
.product:hover {
  // color: var(--color1);
  box-shadow: 0px 20px 30px 0px rgba(0,0,0,0.18);
}
@media screen and (max-width: 420px) {
  .product {
    border:none;
  }
  .product:hover {
    color: var(--color1);
    box-shadow: none;
  }
}
</style>
