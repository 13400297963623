<template>
  <div class="simpleHeader">
    <router-link :to="{ name: 'Index' }" class="logo" :title="$t('Anasayfa')"></router-link>
  </div>
</template>

<script>
export default {
  props: ['info'],
  data () {
    return {
      subMenu: false
    }
  },
  computed: {
    cart () {
      return this.$store.state.cart
    }
  }
}
</script>

<style scoped lang="scss">
.simpleHeader {
  display: grid;
  place-items: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}
.logo {
  margin: auto;
  width: 200px;
  height: 35px;
  display: grid;
  align-items:center;
  background: url('../assets/logo.svg') center no-repeat;
  background-size: contain;
}
@media screen and (max-width: 768px) {
  .logo {
    width: 144px;
    height: 60px;
  }
}
</style>
