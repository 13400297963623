<template>
  <div id="app">
    <router-view :info="info" v-if="isLoaded"/>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      isLoaded: false
    }
  },
  metaInfo () {
    const title = this.$i18n.t('Rent A Tech')
    return {
      title: title,
      titleTemplate: '%s - ' + title
    }
  },
  computed: {
    info () {
      return this.$store.state.info
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resize)
  },
  beforeMount () {
    /*
    const savedLocale = localStorage.getItem('lang')
    if (!savedLocale) {
      this.$i18n.locale = this.$route.params.lang
      localStorage.setItem('lang', this.$route.params.lang)
    }
    */
    this.getInfo()
  },
  watch: {
    $route (to, from) {
      document.querySelector('body').style.overflow = 'auto'
      this.$store.dispatch('setBottomMenu', false)
      this.$store.dispatch('setCatMenu', false)
    }
  },
  methods: {
    resize: function () {
      const size = { width: this.$fnc.getWidth(), height: this.$fnc.getHeight() }
      this.$store.dispatch('setSize', size)
    },
    getInfo: function () {
      const menu = (this.$store.state.info.menu)
      const sId = localStorage.getItem('sId')
      if (!menu) {
        const payload = {
          sId: sId
        }
        axios.post('api/info.php', payload).then(response => {
          if (response.data.error) {
            localStorage.removeItem('sId')
            this.getInfo()
          } else {
            this.$store.dispatch('setInfo', response.data)
            this.$store.dispatch('setUser', response.data.user)
            this.$store.dispatch('setCart', response.data.user.cartCount)
            localStorage.setItem('sId', response.data.user.sId)
          }
        }).then(response => {
          this.isLoaded = true
        })
      }
    }
  }
}
</script>

<style>
  @import './assets/css/main.css';
</style>
