import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    info: {},
    home: {},
    cart: 0,
    user: {
      sId: false,
      email: false,
      userId: false,
      userName: false
    },
    bottomMenu: false,
    catMenu: false,
    mobileWidth: 769,
    width: window.innerWidth,
    height: document.body.scrollHeight,
    sort: 'new',
    showPriceSorter: false,
    pageSize: 24,
    priceWithVat: false,
    fileCdn: 'https://www.kareiki.com/rentatech/files/',
    mediaCdn: 'https://www.kareiki.com/rentatech/media/',
    baseUrl: 'https://9mhz.com'
  },
  mutations: {
    setInfo (state, info) {
      state.info = info
    },
    setUser (state, user) {
      state.user = user
    },
    setHome (state, home) {
      state.home = home
    },
    setCart (state, cart) {
      state.cart = cart
    },
    setSort (state, sort) {
      state.sort = sort
    },
    setBottomMenu (state, bottomMenu) {
      state.bottomMenu = bottomMenu
    },
    setCatMenu (state, catMenu) {
      state.catMenu = catMenu
    },
    setSize (state, size) {
      state.width = size.width
      state.height = size.height
    }
  },
  actions: {
    setInfo (context, info) {
      context.commit('setInfo', info)
    },
    setUser (context, user) {
      context.commit('setUser', user)
    },
    setHome (context, home) {
      context.commit('setHome', home)
    },
    setCart (context, cart) {
      context.commit('setCart', cart)
    },
    setSort (context, sort) {
      context.commit('setSort', sort)
    },
    setBottomMenu (context, bottomMenu) {
      context.commit('setBottomMenu', bottomMenu)
    },
    setCatMenu (context, catMenu) {
      context.commit('setCatMenu', catMenu)
    },
    setSize (context, size) {
      context.commit('setSize', size)
    }
  }
})
