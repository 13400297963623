<template>
  <div style="background:#E5E6EA;height:100vh; overflow:auto;">
    <SimpleHeader :info="info" />
    <div class="container modal login">
      <div class="sol">
        <LoginForm v-if="$route.name === 'Login'" :info="info" :from="From" />
        <LoginRegisterForm v-else-if="$route.name === 'Register'" :info="info" />
        <LoginForgetPasswordForm v-else-if="$route.name === 'ForgetPassword'" :info="info" />
      </div>
      <div v-if="$store.state.mobileWidth < $store.state.width" class="sag"></div>
    </div>
    <SimpleFooter :info="info" />
  </div>
</template>

<style lang="scss">
.modal {
  background:#fff;
  border-radius: 4px;
  box-shadow: 0px 0px 30px 0px rgba(0,0,0,.1);
  overflow: hidden;
  width: 900px;
}
.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: calc(100vh - 180px);
  .sol {
    padding: 30px;
    width: 100%;
    form {
      width:100%;
      .formError {
        font-size: 13px;
        border: 2px dashed var(--color1);
        min-height: 36px;
        display: grid;
        place-items: center;
        padding: 8px;
        border-radius: 8px;
        background: #ffece6;
        margin-top:5px;
        margin-bottom:5px;
      }
      h1 {
        font-size: 22px;
      }
      label {
        width: 100%;
        display: block;
        font-size: 11px;
        color: #afafaf;
        margin-bottom: 3px;
      }
      .red {
        color: red;
      }
      div {
        margin-bottom: 12px;
      }
      input[type=text],input[type=email],input[type=password] {
        border: 1px solid #dbdbdb;
        outline: none;
        background: #fff;
        padding: 14px 8px 14px 8px;
        width: 100%;
      }
      button {
        border: 1px solid var(--color1);
        outline: none;
        background: var(--color1);
        color: #fff;
        padding: 14px 8px 14px 8px;
        width: 100%;
        transition: all 0.1s ease-in;
        display: grid;
        grid-template-columns: 40px auto;
        align-items: center;
        text-align: left;
        i {
          text-align: center;
          font-size: 16px;
        }
      }
      button:hover {
        background:#333;
        border-color: #333;
        color: #fff;
      }
      .facebook {
        background: var(--facebookColor);
        border-color: var(--facebookColor);
        color: #fff;
      }
      .google {
        background: var(--googleColor);
        border-color: var(--googleColor);
        color: #fff;
      }
      a {
        font-size: 13px;
        color: #666;
        display: grid;
        grid-template-columns: 22px auto;
        align-items: center;
        i {
          font-size: 11px;
          text-align: center;
        }
      }
      a:hover {
        color: var(--color1);
      }
    }
  }
  .sag {
    height: 100%;
    background: url('https://picsum.photos/600/600') no-repeat center;
    background-size: cover;
    background-position: center;
  }
}

@media screen and (max-width:768px) {
  .modal {
    width: calc(100vw - 40px);
  }
  .login {
    grid-template-columns: 1fr;
    min-height: unset;
    .sag {
      display: none;
    }
  }
}
@media screen and (min-width:768px){
  .modal {
    width: calc(100vw - 40px);
    margin: auto;
  }
}
@media screen and (min-width:1024px){
  .modal {
    width: 900px;
    margin: auto;
  }
}

</style>

<script>
import LoginForm from '@/small-components/LoginForm'
import LoginRegisterForm from '@/small-components/LoginRegisterForm'
import LoginForgetPasswordForm from '@/small-components/LoginForgetPasswordForm'
export default {
  components: { LoginForm, LoginRegisterForm, LoginForgetPasswordForm },
  props: ['info'],
  data () {
    return {
      From: null
    }
  },
  metaInfo () {
    let title = ''
    if (this.$route.name === 'Login') {
      title = this.$i18n.t('Giriş Yapın')
    } else if (this.$route.name === 'Register') {
      title = this.$i18n.t('Üye Olun')
    } else if (this.$route.name === 'ForgetPassword') {
      title = this.$$i18n.t('Şifenizi Sıfırlayın')
    }
    return {
      title: title,
      meta: [{ vmid: 'description', name: 'description', content: '' }]
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.From = (from.name)
    })
  }
}
</script>
