<template>
  <div>
    <CartLogin :user="user" :error="loginError" />
    <CartAddress :user="user" />
    <CartPaymentMethods :paymentOptions="paymentOptions" :selectedPayment="$parent.selectedPayment" />
  </div>
</template>

<script>
import axios from 'axios'
import CartLogin from '@/small-components/CartLogin'
import CartAddress from '@/small-components/CartAddress'
import CartPaymentMethods from '@/small-components/CartPaymentMethods'
export default {
  props: ['paymentOptions'],
  components: { CartLogin, CartAddress, CartPaymentMethods },
  data () {
    return {
      user: this.$store.state.user,
      loginError: false,
      invoiceAddress: {},
      deliveryAddress: {},
      isSavingCart: false,
      contracts: true,
      contractError: false,
      instalment: 1
    }
  },
  methods: {
    processPayment: async function () {
      console.clear()
      event.preventDefault()
      const payload = {
        paymentMethod: this.$parent.selectedPayment,
        instalment: this.instalment,
        user: this.$store.state.user,
        invoiceAddress: this.invoiceAddress,
        deliveryAddress: this.deliveryAddress,
        uniq: this.$parent.uniq
      }
      if (this.contracts === false) {
        // alert('Sözleşmeleri onaylamalısınız')
        this.contractError = true
        this.isSavingCart = false
      } else {
        this.contractError = false
        this.isSavingCart = true
        const fields = []
        const form = document.querySelector('.fields')
        var str = ''
        await axios.post('/api/saveOrder.php', payload).then(response => {
          const data = response.data.data
          fields.push({ name: 'uniq', value: data.uniq })
          fields.push({ name: 'reference', value: data.reference })
          fields.forEach(f => {
            str += '<input type="hidden" name="' + f.name + '" value="' + f.value + '">'
          })
          form.innerHTML = str
          document.querySelector('#paymentForm').submit()
        })
      }
    },
    selectPayment: function (idx) {
      this.$parent.selectedPayment = this.paymentOptions[idx]
    },
    login: async function (login) {
      const payload = {
        email: login.email,
        pass: login.pass,
        user: this.$store.state.user
      }
      await axios.post('/api/login.php', payload).then(response => {
        this.formError = ''
        const err = response.data.error
        if (err === 'none') {
          this.$store.dispatch('setUser', response.data.user)
          this.user = this.$store.state.user
          this.loginError = false
        } else {
          this.loginError = err
        }
      })
    },
    register: async function (login, contract, messagePermission) {
      const payload = {
        email: login.email,
        pass: login.pass,
        name: login.name,
        user: this.$store.state.user,
        contract: contract,
        messagePermission: messagePermission
      }
      await axios.post('/api/register.php', payload).then(response => {
        this.formError = ''
        const err = response.data.error
        if (err === 'none') {
          this.$store.dispatch('setUser', response.data.user)
          this.user = this.$store.state.user
          this.loginError = false
        } else {
          this.loginError = err
        }
      })
    },
    logout: async function () {
      const payload = {
        user: this.$store.state.user
      }
      await axios.post('/api/logout.php', payload).then(response => {
        this.$store.dispatch('setUser', response.data.user)
        this.user = false
        this.invoiceAddress = {}
        this.deliveryAddress = {}
      })
    }
  }
}
</script>
